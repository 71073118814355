<template>
  <div id="offers">
    <Offers-Sections-Plans id="entry" />
    <BannerSupportBuyRealEstate class="banner" url="https://s.horiz.io/chasseimmo_offres" />
    <Offers-Sections-Benefits
      v-if="displayBenefits"
      id="benefits"
      :assurance_pno="avantages.assurance_pno"
      :garantie_loyers_impayes="avantages.garantie_loyers_impayes"
      :expert_comptable="avantages.expert_comptable"
    />
    <nuxt-lazy-hydrate when-visible>
      <lazy-sections-f-a-q id="faq" class="animate" :faq="faq" />
    </nuxt-lazy-hydrate>
  </div>
</template>

<script setup>
const config = useRuntimeConfig()

const faq = [
  {
    title: "Est-ce que l'abonnement est déductible des impôts ?",
    content:
      "Oui dans le cas d'un bien loué meublé, imposé au réel ou encore d'une SCI à l'IS. Par exemple, si vous êtes célibataire, imposé dans la tranche des 30%, vous ferez une économie d'impôts de 30% du prix de votre abonnement.<br /><br />Si vous êtes en location non meublée, c'est inclus dans les 20€ forfaitaires déduits dans votre déclaration d'impôts.",
  },
  {
    title: 'Puis-je résilier mon abonnement quand je le souhaite ?',
    content:
      "Oui. Vous pouvez stopper votre abonnement à tout moment. <br /><br />Il continuera à courir jusqu'à la fin de la période initiale choisie. <br /><br />Vous pouvez également le réactiver à tout moment en un clic en fonction de vos phases de recherche.",
  },
  {
    title: 'Puis-je payer avec Paypal ou virement ?',
    content:
      'Non, pour le moment nous proposons uniquement le paiement par carte bancaire (Visa, Amex, Mastercard, etc.).',
  },
  {
    title: "Qu'est ce qu'une simulation ?",
    content:
      'Il s\'agit du calcul effectué pour dans le parcours "Faire un bien rentable". Chaque scénario proposé = une simulation.',
  },
  {
    title: 'Qu’est ce que le business plan de mon projet ?',
    content:
      "Il s'agit de l'export de votre rapport de rentabilité en format PDF. <br /><br />Très utile si vous souhaitez le présenter à votre banquier pour lui démontrer la pertinence de votre projet par exemple.",
  },
  {
    title: 'Qu’est ce que la gestion locative en ligne ?',
    content:
      'Notre outil vous accompagne dans toutes les étapes de la gestion locative pour vous aider à ce que cela soit moins fastidieux et chronophage. <br /><br />Notre plateforme a été conçue pour être éducative et comprise par tous, pour que vous soyez autonome.',
  },
  {
    title: 'Quelles sont les différences entre notre logiciel de gestion et une agence ?',
    content:
      "Notre logiciel de gestion locative vous permet de gérer vous-même vos biens de façon efficace en automatisant un maximum de tâches pénibles, tout en économisant des éventuels frais de gestion. <br /><br /> Cependant, ce sera à vous de chercher et trouver les locataires ou encore de gérer les éventuels sinistres et réparations qui incombent au propriétaire bailleur. <br /><br /> Si vous souhaitez ne rien avoir à gérer et tout déléguer, préférez signer un contrat de gestion locative avec un mandataire professionnel qui s’occupera alors des démarches qui vont au-delà de la simple gestion locative.<br /><br />Nous pouvons d’ailleurs vous <a href='https://citruce.typeform.com/to/DqElXZA7?utm_source=horiz&utm_medium=page-offre&utm_campaign=faq' target='_blank'>mettre en relation avec nos partenaires.</a>",
  },
  {
    title: 'Que représente un bien en gestion ?',
    content:
      "Il s'agit d'un bien (ou un local) immobilier mis en location. <br /><br />Si vous possédez un immeuble avec plusieurs appartements, chaque appartement est un bien. <br /><br />Pour un bien, le nombre de locataires, les quittances, les paiements, les déclarations foncières, etc. sont illimités.",
  },
  {
    title: 'Le nombre de biens est-il vraiment illimité ?',
    content:
      "Vous pouvez gérer jusqu'à 50 biens. Au delà de cette limite, merci de nous contacter pour que nous puissions étudier votre besoin.",
  },
  {
    title: "Qu'est ce que le bail numérique ?",
    content:
      'Le bail numérique vous permet de gagner beaucoup de temps ! Il vous permet de signer le bail à distance grâce à la signature électronique.<br /><br />Locataires et garants signent le bail depuis leur ordinateur. Plus besoin de se déplacer. Dès que toutes les parties prenantes ont terminé de signer, vous êtes informés.',
  },
  {
    title: "Qu'est ce que l'aide à la déclaration d'impôts ?",
    content:
      "Le moment de la déclaration d'impôts est toujours un calvaire !<br /><br />Dans le cas où vous possédez une location non meublée en tant que particulier , nous pré-remplissons automatiquement les champs de votre déclaration d'impôt.<br /><br />Pour une location meublée ou en SCI, vous pourrez entrer les données fournies par le comptable.",
  },
  {
    title: "Puis-je profiter de vos avantages exclusifs sans m'abonner ?",
    content: 'Nos avantages exclusifs sont ouverts à tous, n’hésitez plus à en profiter dés maintenant.',
    partnerRestricted: true,
  },
]

// Metas
const title = "Nos offres d'abonnement et avantages exclusifs - Horiz.io"

useMeta({
  title,
  description:
    "Découvrez nos offres d'abonnement annuel et mensuel. Profitez d'avantages exclusifs réservés aux abonnés : PNO, GLI, expert-comptable...",
  imageAlt: "Horiz.io, calculer le rendement d'un investissement locatif",
  url: `${config.public.hpBaseUrl}/offres`,
})

useHead({
  title,
  link: [
    {
      rel: 'canonical',
      href: `${config.public.hpBaseUrl}/offres`,
    },
  ],
})
</script>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '../stores/auth'
import { usePartnerStore } from '../stores/partner'

export default {
  mounted() {
    const tracking = useTracking()
    tracking.trackAfterMounted('Offres loaded')
    if (this.$route.query?.tracking && this.$route.query?.positionBouton) {
      tracking.trackAfterMounted('[GESTION_LP] Clic Bouton Commencer', {
        positionBouton: this.$route.query.positionBouton,
      })
    }
  },
  computed: {
    ...mapState(useAuthStore, ['user']),
    businessPartner() {
      const partnerCookie = useCookie('partner_name')

      return this.user?.partner_name || partnerCookie.value
    },
    avantages() {
      const partnerStore = usePartnerStore()

      return {
        assurance_pno: partnerStore.assurance_pno,
        garantie_loyers_impayes: partnerStore.garantie_loyers_impayes,
        expert_comptable: partnerStore.expert_comptable,
      }
    },
    displayBenefits() {
      return (
        !this.businessPartner ||
        this.avantages.assurance_pno ||
        this.avantages.garantie_loyers_impayes ||
        this.avantages.expert_comptable
      )
    },
  },
}
</script>

<style lang="less" scoped>
#homepage {
  min-height: 100%;
}

#faq :deep(.accordion-container) .title-container {
  .title {
    font-weight: var(--ds-weight-semi-bold);
  }
  img {
    margin-left: 15px;
  }
}

:deep(section.benefits-section) {
  height: auto !important;
}
:deep(section.section.faq) {
  margin-top: 50px;
  height: auto !important;
}

#benefits {
  margin-top: 50px;
}

#faq {
  @media @bp-desktop {
    padding-left: var(--ds-gutter-xl);
    padding-right: var(--ds-gutter-xl);
    height: 100% !important;
    margin-bottom: 30px;
    :deep(.section-container) {
      max-width: @bp-desktop-max-width;
      & > .title {
        margin-top: 15px;
        margin-bottom: 55px;
      }
    }
    :deep(.accordion-container) {
      padding: 23px 20px 30px 30px;
    }
  }
}
#faq :deep(.container) {
  .titles {
    .faq-title {
      &:not(.current) {
        color: #6b6651;
      }
      @media @bp-desktop {
        font-size: 13px;
      }
    }
  }
}
.banner {
  margin: 32px auto 0;
  max-width: 1040px;
}
</style>
