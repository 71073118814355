<template>
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        opacity="0.4"
        d="M16.438 1.36182H2.73967C1.2266 1.36182 0 2.58153 0 4.08592V17.7065C0 19.2108 1.2266 20.4306 2.73967 20.4306H16.438C17.9511 20.4306 19.1777 19.2108 19.1777 17.7065V4.08592C19.1777 2.58153 17.9511 1.36182 16.438 1.36182ZM13.0549 12.8979C13.4562 13.297 13.4562 13.9434 13.0549 14.3425C12.6533 14.7418 12.0034 14.7414 11.6021 14.3425L9.58885 12.3406L7.57558 14.3425C7.174 14.7418 6.52402 14.7414 6.12278 14.3425C5.72146 13.9434 5.72146 13.297 6.12278 12.8979L8.13606 10.8961L6.12278 8.89435C5.72146 8.49531 5.72146 7.84885 6.12278 7.44981C6.5241 7.05077 7.17426 7.05077 7.57558 7.44981L9.58885 9.45164L11.6021 7.44981C12.0034 7.05077 12.6536 7.05077 13.0549 7.44981C13.4562 7.84885 13.4562 8.49531 13.0549 8.89435L11.0416 10.8961L13.0549 12.8979Z"
        fill="#8F8F8F"
      />
      <path
        d="M11.0421 10.8965L13.0554 8.89466C13.4567 8.49562 13.4567 7.8492 13.0554 7.45016C12.6541 7.05112 12.0039 7.05112 11.6026 7.45016L9.58933 9.45199L7.57605 7.45016C7.17473 7.05112 6.52457 7.05112 6.12326 7.45016C5.72194 7.8492 5.72194 8.49562 6.12326 8.89466L8.13653 10.8965L6.12326 12.8983C5.72194 13.2974 5.72194 13.9438 6.12326 14.3428C6.52449 14.7418 7.17448 14.7421 7.57605 14.3428L9.58933 12.341L11.6026 14.3428C12.0038 14.7418 12.6538 14.7421 13.0554 14.3428C13.4567 13.9438 13.4567 13.2974 13.0554 12.8983L11.0421 10.8965Z"
        fill="#8F8F8F"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19.1777" height="21.7929" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
