const horiz_plus_description = [
  {
    title: 'Simulateur de rendement',
    data: [
      {
        label: 'Nombre de nouvelles simulations',
        text: '30 / mois',
        tooltip: 'Le nombre de simulations sauvegardées est limité également à 30 / mois'
      },
      {
        label: 'Simulations location nue, meublée LMNP et SCI',
        type: 'checkboxTicked',
      },
      {
        label: 'Recherche de biens exclusifs Horiz.io',
        type: 'checkboxTicked',
      },
      {
        label: 'Rapport d’investissement complet et imprimable',
        type: 'checkboxTicked',
      },
    ],
  },
  {
    title: 'Gestion locative en ligne',
    data: [
      {
        label: 'Nombre de biens à gérer',
        text: 'Illimité',
        prependStar: true,
      },
      {
        label: 'Mise en location et gestion',
        type: 'checkboxTicked',
      },
      {
        label: 'Bail numérique',
        text: '1 gratuit',
        prependStar: true,
      },
      {
        label: 'Performance patrimoniale',
        type: 'checkboxTicked',
      },
      {
        label: 'Aide à la déclaration d’impôts',
        type: 'checkboxTicked',
      },
    ],
  },
  {
    data: [
      {
        label: 'Accès au support',
        type: 'checkboxTicked',
      },
    ],
  },
]

const DISCOUNT_PERCENTAGE = {
  horizPlusUncommitted1999: 34,
  horizPlusYearly99: 58,
}

const PRICING = {
  horizPlusUncommitted1999: 19.99,
  horizPlusYearly99: 19.99,
}

const plans = [
  // Horiz+ sans engagement
  {
    descriptions: horiz_plus_description,
    title: 'HORIZ+',
    stripeProductName: 'HORIZ+ SANS ENGAGEMENT',
    stripe_price_id: 'horizPlusUncommitted1999',
    is_committed: false,
    frequency: 'monthly',
    price: {
      month: PRICING.horizPlusUncommitted1999,
      year: PRICING.horizPlusUncommitted1999 * 12,
    },
  },
  // Horiz+ avec engagement
  {
    descriptions: horiz_plus_description,
    title: 'HORIZ+',
    stripeProductName: 'HORIZ+ Annuel',
    stripe_price_id: 'horizPlusYearly99',
    is_committed: false,
    frequency: 'yearly',
    price: {
      month: PRICING.horizPlusYearly99,
      year: PRICING.horizPlusYearly99 * 12,
    },
    discount: {
      percent: DISCOUNT_PERCENTAGE.horizPlusYearly99,
      price: { month: 8.25 },
    },
  },
]

const sortRevAlpha = (a, b) => {
  let ta = a.title.toLowerCase(),
    tb = b.title.toLowerCase()

  if (ta < tb) {
    return 1
  } else if (ta > tb) {
    return -1
  }

  return 0
}

export const plansYearly = plans.filter((p) => p.frequency === 'yearly').sort(sortRevAlpha)
export const plansMonthly = plans.filter((p) => p.frequency === 'monthly').sort(sortRevAlpha)
