<template>
  <Section
    id="entry"
    class="plans flex-row v-top h-center test"
  >
    <div class="plan-container flex-col h-center">
      <h1 class="title">
        Une offre
        <span class="title-secondary">avec ou sans engagement</span>
      </h1>

      <div class="toggle-container flex-row">
        <span
          class="label"
          :class="{ active: monthlyToggle }"
        >
          Mensuelle
          <span class="line-break"><br /></span>
          19,99€ / mois
        </span>
        <label class="switch">
          <input
            type="checkbox"
            :checked="!monthlyToggle"
            @click="handleMonthlyToggle"
          />
          <span class="slider" />
        </label>
        <span
          class="label"
          :class="{ active: !monthlyToggle }"
        >
          Annuelle
          <span class="line-break"><br /></span>
          8,25€ / mois
        </span>
      </div>

      <div
        id="plan-container"
        class="plan-container flex-col"
      >
        <div class="tab-container flex-row h-center">
          <div
            v-for="(plan, index) in plans"
            :key="`plantitle${index}`"
            :class="{ active: currentPlan == plan.title }"
            class="plan-title"
            @click="switchPlan(plan.title)"
          />
        </div>

        <div
          ref="scroll-container"
          class="plan-card-container flex-row"
        >
          <div
            v-for="(plan, index) in plans"
            :id="plan.title"
            :key="`plan${index}`"
            class="plan-card flex-col h-center"
            :class="{ selected: plan.title == currentPlan, discount: plan.discount }"
            @mouseover="switchPlan(plan.title)"
          >
            <div
              v-if="plan.discount"
              class="promotion-mobile hide-on-desktop flex-center"
            >
              <span>-{{ plan.discount.percent }}%</span>
              <span class="strikeout">{{ pricePerMonthWithoutPromo(plan) }}€/mois</span>
            </div>

            <div
              v-if="plan.discount"
              class="promotion-desktop hide-on-mobile flex-center flex-col"
            >
              <span class="promotion">
                <b>-{{ plan.discount.percent }}</b>
                %
              </span>
              <span class="strikeout">{{ pricePerMonthWithoutPromo(plan) }}€/mois</span>
              <span class="discount-explanation" />
            </div>

            <div
              v-else
              class="hide-on-desktop no-promo-padding"
            />

            <div class="title-container flex-row flex-center">
              <div v-if="plan.discount">
                <div class="yearly-price">
                  <div>
                    <span class="price">{{ pricePerMonth(plan) }}€</span>
                    <span class="per-year">/mois</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <span class="price monthly-price">{{ pricePerMonthWithoutPromo(plan) }}€</span>
                <span class="per-year">/mois</span>
              </div>
            </div>

            <div
              v-if="monthlyToggle"
              class="subtitle-container flex-row flex-center"
            >
              Sans engagement
            </div>
            <div
              v-else
              class="subtitle-container flex-row flex-center"
            >
              <span class="desc">Paiement annuel</span>
            </div>

            <uikit-button
              type="primary"
              class="subscribe-button"
              @click="redirectToPayment(plan.stripe_price_id)"
            >
              S'abonner
            </uikit-button>

            <div
              v-for="(description, index2) in plan.descriptions"
              :key="`container${index}${index2}`"
              class="description-container"
            >
              <h4
                :key="`${index}${index2}`"
                class="title"
              >
                {{ description.title }}
              </h4>

              <div
                v-for="(data, index3) in description.data"
                :key="`${index}${index2}${index3}`"
                class="line flex-row"
              >
                <span
                  class="subtitle"
                  :class="{ 'text-grayed': data.type == 'checkboxGrayed' }"
                >
                  {{ data.label }}
                  <uikit-dropdown
                    v-if="data.tooltip"
                    class="hz-home-dis-inline"
                  >
                    <uikit-tooltip-icon class="tooltip-icon" />
                    <template #overlay>
                      <div class="tooltip">
                        <span v-html="data.tooltip" />
                      </div>
                    </template>
                  </uikit-dropdown>
                </span>
                <span
                  v-if="data.text"
                  class="text"
                  :class="{ 'text-grayed': data.type == 'checkboxGrayed' }"
                >
                  <uikit-star-icon
                    v-if="data.prependStar"
                    class="star-icon"
                    width="17px"
                    height="17px"
                  />
                  {{ data.text }}
                </span>
                <Offers-Components-PurpleCheckboxIcon
                  v-else-if="data.type == 'checkboxTicked'"
                  class="checkbox-ticked"
                />
                <Offers-Components-GrayCheckboxIcon
                  v-else-if="data.type == 'checkboxGrayed'"
                  class="checkbox-grayed"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="pro-card plan-card flex-col h-center">
          <h3 class="pro-title">
            Passez au niveau supérieur
            <br class="hide-on-mobile" />
            avec notre
            <span class="title-secondary">offre exlusive pour les PRO</span>
          </h3>
          <a
            class="pro-cta"
            target="_blank"
            href="https://horiz.io/ressources/logiciel-agents-immobiliers?utm_source=horiz&utm_medium=offres&utm_content=cta&utm_campaign=offre-pro"
          >
            Découvrir l’offre PRO
          </a>
        </div>
      </div>
    </div>
  </Section>
</template>

<script>
import { trackAboSelectItem, trackAboViewItem } from '@/helpers/conversion/conversion-abo'
import { mapState } from 'pinia'
import { plansMonthly, plansYearly } from '../../../helpers/plans'
import { useAuthStore } from '../../../stores/auth'
import { UikitStarIcon, UikitButton, UikitDropdown, UikitTooltipIcon } from '@hz/ui-kit'

export default {
  components: { UikitStarIcon, UikitButton, UikitDropdown, UikitTooltipIcon },
  data: function () {
    return {
      monthlyToggle: false,
      currentPlan: 'HORIZ+',
      planScrollNode: null,
      planCardContainerNode: null,
      timeoutId: null,
      informationPrice: '0',
    }
  },
  computed: {
    ...mapState(useAuthStore, ['loggedIn']),
    plans() {
      return this.monthlyToggle ? plansMonthly : plansYearly
    },
    activePlan() {
      return this.plans[0]
    },
  },
  mounted() {
    this.trackAboViewItem()

    this.planScrollNode = this.$refs['scroll-container']
    this.planScrollNode.addEventListener('scroll', this.managePlanScroll)
  },
  beforeUnmount() {
    this.planScrollNode.removeEventListener('scroll', this.managePlanScroll)
  },
  methods: {
    handleMonthlyToggle() {
      this.monthlyToggle = !this.monthlyToggle
      this.animateTitle()
      this.trackAboViewItem()
    },
    switchPlan(plan) {
      this.currentPlan = plan

      this.planScrollNode.scrollTo({
        left: document.getElementById(plan).offsetLeft,
        behavior: 'smooth',
      })
    },
    animateTitle() {
      const planContainer = document.getElementById('plan-container')

      if (!planContainer.classList.contains('animate-title')) planContainer.classList.add('animate-title')

      if (this.timeoutId === null) {
        this.timeoutId = setTimeout(() => {
          planContainer.classList.remove('animate-title')
          this.timeoutId = null
        }, 250)
      }
    },
    managePlanScroll() {
      const scrollPosition = this.planScrollNode.scrollLeft + this.planScrollNode.offsetWidth / 2
      const elements = []

      this.planScrollNode.childNodes.forEach((value, index) => {
        const elemPosition = value.offsetLeft + value.offsetWidth / 2
        elements.push({ id: value.id, onScreenPixels: elemPosition - scrollPosition })
      })

      const element = elements.reduce((prev, current) => {
        if (Math.abs(prev.onScreenPixels) > Math.abs(current.onScreenPixels)) return current

        return prev
      })

      if (this.currentPlan !== element.id) this.currentPlan = element.id
    },
    pricePerMonth(plan) {
      if (plan.discount) {
        return plan.discount.price.month
      }

      return plan.price.month
    },
    pricePerMonthWithoutPromo(plan) {
      return plan.price.month
    },
    redirectToPayment(stripe_price_id) {
      this.trackAboSelectItem()

      const priceId = stripe_price_id
      const redirectURI = this.$route.query.redirectURI || window.document.referrer
      location.href = `${this.$config.public.appBaseUrl}/payment?id=${priceId}&behavior=register&redirectURI=${redirectURI}`
    },
    getCoupon() {
      let coupon = ''
      if (this.loggedIn) {
        const pcFromCookie = useCookie('hzd-pc').value
        if (pcFromCookie) {
          coupon = pcFromCookie
        }
      }

      return coupon
    },
    trackAboViewItem() {
      const plan = this.activePlan
      const coupon = this.getCoupon()

      trackAboViewItem(
        plan.stripe_price_id,
        plan.is_committed ? plan.discount.price.month * 12 : plan.discount.price.month,
        plan.stripeProductName,
        coupon,
      )
    },
    trackAboSelectItem() {
      const plan = this.activePlan
      const coupon = this.getCoupon()

      trackAboSelectItem(
        plan.stripe_price_id,
        plan.frequency === 'monthly' ? plan.price.month : plan.price.year,
        plan.stripeProductName,
        coupon,
      )
    },
  },
}
</script>

<style lang="less" scoped>
.tooltip {
  padding: 20px;
}

.tooltip-icon {
  color: var(--ds-color-primary-100);
  margin-left: 8px;
}

.hz-home-dis-inline {
  display: inline-block;
}

.hz-home-v-sub {
  vertical-align: sub;
}

.title-secondary {
  color: var(--ds-color-primary-100);
}

.plans {
  height: auto !important;

  @media @bp-desktop-xl {
    max-width: 100% !important;
  }
}

.plan-container {
  .title {
    font-size: 24px;
    font-weight: var(--ds-weight-semi-bold);
    color: #272727;
    text-align: center;

    @media @bp-desktop {
      font-size: 32px;
    }

    strong {
      color: var(--ds-color-primary-100);
      font-weight: inherit;
    }
  }

  .toggle-container {
    margin-top: 40px;
    position: relative;

    @media @bp-desktop {
      margin-top: 60px;
    }

    .floating-text {
      position: absolute;
      top: ~'calc(100% + 10px)';
      white-space: nowrap;

      color: var(--ds-color-primary-100);
      font-size: 16px;
      font-weight: 800;

      .gift-icon {
        width: 12px;
        height: 12px;
        margin-right: 5px;

        position: relative;
        top: 1px;
      }

      .wind-arrow-up-icon {
        transform: rotate(-120deg);
        position: relative;
        bottom: 5px;
      }

      @media @bp-desktop {
        top: 0;
        right: ~'calc(100% + 5px)';
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 68px;
      height: 21px;
      -webkit-tap-highlight-color: transparent;
      margin: 0 15px;
      top: 4px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--ds-color-white-100);
        transition: 0.4s;
        border-radius: 15px;
      }

      .slider:before {
        position: absolute;
        content: '';
        height: 34px;
        width: 34px;
        left: -2px;
        bottom: -7px;
        background-color: var(--ds-color-primary-100);
        transition: 0.4s;
        border-radius: 50%;
        border: 2px solid #ffffff;
      }

      input:checked + .slider:before {
        transform: translateX(37px);
      }
    }

    .label {
      color: #3a3e46;
      font-size: 14px;
      text-align: center;

      @media @bp-desktop {
        font-size: 20px;
      }

      &.active {
        font-weight: 800;
      }

      .line-break {
        @media @bp-desktop {
          display: none;
        }
      }
    }
  }

  .plan-container {
    background: url('~/assets/imgs/purple-fade-background-mobile.svg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100vw;
    margin-bottom: 120px;

    @media @bp-desktop {
      margin-top: 10px;

      &.animate-title .title-container {
        transform: scale(1.2);
        transition: all 0.25s ease-in-out;
      }
    }

    .tab-container {
      margin-top: 51px;
      transition: all 50ms ease;
      transition-delay: 50ms;

      .plan-title {
        color: var(--ds-color-white-100);

        &:first-child {
          margin-right: 30px;
        }

        &:after {
          content: '';
          display: block;
          height: 4px;
          width: 100%;
          background-color: var(--ds-color-white-100);
          border-radius: 4px;
          margin-top: 9px;
          transition: all 50ms ease;
          transition-delay: 50ms;
          opacity: 0;
        }

        &.active {
          font-weight: bold;
          transition: all 50ms ease;
          transition-delay: 50ms;

          &:after {
            opacity: 1;
          }
        }
      }

      @media @bp-desktop {
        display: none;
      }
    }

    @media @bp-desktop {
      background: url('~/assets/imgs/purple-fade-background.svg');
      background-size: cover;
    }
  }

  .plan-card-container {
    margin-top: 30px;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 12px;
    align-items: flex-start;
    justify-content: center;

    @media @bp-desktop {
      margin-top: 0;
      padding: 105px;

      .title-container {
        margin-top: 20px;
      }
    }
  }

  .plan-card {
    background-color: var(--ds-color-white-100);
    border-radius: 5.55px;
    flex-shrink: 0;
    width: 334px;
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 7px;

      @media @bp-desktop {
        margin-right: 70px;
      }
    }

    .title-container {
      transition: all 0.25s ease-in-out;

      .yearly-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .unique-payment {
          font-size: 10px;
          position: relative;
          bottom: 3px;
        }
      }
    }

    @media @bp-desktop {
      width: 488px;
      border: 2px solid #eeeeee;
      border-radius: 9px;
      padding-bottom: 40px;
      transition: all 0.35s ease-in-out;
      transform: scale(1);

      &.selected {
        transform: scale(1.3);
        transition: all 0.35s ease-in-out;

        .title-container {
          .title,
          .price,
          .circle,
          .per-year {
            transform: scale(1.3);
            transition: all 0.35s ease-in-out;
          }

          .circle {
            margin-left: 19px;
          }
          .price {
            margin-left: 15px;
          }
        }
      }
    }

    .no-promo-padding {
      height: 44px;
      width: 1px;
    }

    &:not(.discount) {
      .no-promo-padding {
        display: none;
      }
    }

    &.discount {
      .discount-explanation {
        font-size: 7px;
        font-weight: 400;
        color: var(--ds-color-white-100);
        margin-bottom: 7px;
      }

      .strikeout {
        color: var(--ds-color-white-100);
        font-size: 14px;
        position: relative;
        bottom: 3px;
        margin-left: 12px;

        &:after {
          display: block;
          content: '';
          height: 1px;
          width: 100%;
          background-color: var(--ds-color-white-100);
          position: absolute;
          bottom: 7px;
        }
      }
      .promotion-mobile,
      .promotion-desktop {
        padding: 5px 60px 7px 60px;
        background-color: var(--ds-color-primary-100);
        box-shadow: 32.8405px 32.8405px 21.8937px -16.4203px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        color: var(--ds-color-white-100);
        font-weight: var(--ds-weight-semi-bold);
        font-size: 14px;
        transform: rotate(45deg);
        display: flex;
        flex-direction: column;
        position: relative;
        top: 20px;
        right: -127px;

        .strikeout {
          font-size: 12px;
          font-weight: 400;
          margin: 0;
        }

        .information-icon {
          margin-left: 14px;
          margin-right: 3px;
        }
      }

      .title-container {
        .price {
          color: var(--ds-color-primary-100);

          &.monthly-price {
            color: #272727;
          }
        }
      }

      @media @bp-desktop {
        .promotion-desktop {
          padding: 2px 50px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          top: 40px;
          right: -180px;

          .strikeout {
            margin-left: 12px;
            margin-top: 4px;
          }
          .promotion {
            color: var(--ds-color-white-100);
            font-size: 20px;
          }
        }
      }
    }

    .title-container {
      margin-top: 15px;

      @media @bp-desktop {
        margin-top: 20px;
      }
      .title {
        margin-top: 0px;
        font-weight: 800;
        color: #3a3e46;
        transition: all 0.35s ease-in-out;

        @media @bp-desktop {
          font-size: 19px;
        }
      }

      .circle {
        width: 6px;
        height: 6px;
        background-color: var(--ds-color-primary-100-lighten);
        border-radius: 50%;
        margin-left: 5px;
        transition: all 0.35s ease-in-out;

        position: relative;
        top: 2px;
      }

      .price {
        font-size: 22px;
        color: #272727;
        font-weight: 800;
        transition: all 0.35s ease-in-out;

        @media @bp-desktop {
          font-size: 20px;
        }
      }

      .per-year {
        font-size: 18px;
        color: #BABDC2;
        font-weight: 500;
        margin-left: 6px;

        position: relative;
        top: 1px;
        transition: all 0.35s ease-in-out;
      }
    }

    .subtitle-container {
      margin-top: 6px;

      .desc {
        font-size: 16px;
        &.grey {
          color: #8f8f8f;
        }
      }

      .price {
        font-weight: var(--ds-weight-semi-bold);
        font-size: 16px;
        margin-left: 5px;
      }

      .per-month {
        color: #D5D7DB;
        font-size: 16px;
        margin-left: 1px;
      }
    }

    .subscribe-button {
      margin-top: 20px;
      padding-left: 30px;
      padding-right: 30px;
      font-weight: var(--ds-weight-semi-bold);
    }

    .description-container {
      width: 100%;
      padding: 0 13px;

      @media @bp-desktop {
        padding: 0 23px;
      }

      .title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 25px;
        text-align: left;

        @media @bp-desktop {
          font-size: 16px;
          margin-top: 30px;
        }
      }

      .line {
        margin-top: 12px;
        justify-content: space-between;

        @media @bp-desktop {
          margin-top: 12px;
        }

        .subtitle {
          font-size: 13px;
          font-weight: 300;

          @media @bp-desktop {
            font-size: 14px;
          }
        }

        .checkbox-ticked,
        .checkbox-grayed {
          height: 14px;
          position: relative;
          top: 4px;
        }

        .text {
          font-size: 14px;
          font-weight: 800;
          color: var(--ds-color-primary-100);

          @media @bp-desktop {
            font-size: 14px;
          }

          .star-icon {
            margin-bottom: -2px;
          }
        }
        .text-grayed {
          color: #c9c9c9;
        }
      }
    }
  }
}

.pro-card {
  margin: 0 auto;
  margin-bottom: -120px;
  text-align: center;
  padding: 40px 13px 40px 13px;

  @media @bp-desktop {
    width: 634px !important;
    padding: 60px 13px 60px 13px;
  }

  .pro-title {
    color: var(--ds-color-gray-100);
    text-align: center;
    font-style: normal;
    font-weight: var(--ds-weight-semi-bold);
    margin-bottom: 28px;
    font-size: 24px;

    @media @bp-desktop {
      font-weight: var(--ds-weight-semi-bold);
      font-size: 26px;
    }
  }

  .title-secondary {
    color: #f4be7e;
  }
  .pro-cta {
    display: flex;
    width: 237px;
    padding: 12px 24px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    color: var(--ds-color-white-100);
    border-radius: 4px;
    background: var(--ds-color-gray-100);
    text-decoration: none;
  }
}
</style>
