import { trackGA4Event } from '@/helpers/track'

export const trackAboViewItem = (aboId, aboPrice, aboName, codePromo) => {
  trackGA4Event('view_item', {
    currency: "EUR",
    value: aboPrice,
    items: [
      {
        item_id: aboId,
        item_name: aboName,
        currency: "EUR",
        price: aboPrice,
        coupon: codePromo,
        discount: 0,
        item_category: 'Subscription',
        item_category2: undefined,
        item_brand: 'Horiz.io',
        quantity: 1,
      },
    ]
  })
}

export const trackAboSelectItem = (aboId, aboPrice, aboName, codePromo) => {
  trackGA4Event('select_item', {
    currency: "EUR",
    value: aboPrice,
    items: [
      {
        item_id: aboId,
        item_name: aboName,
        currency: "EUR",
        price: aboPrice,
        coupon: codePromo,
        discount: 0,
        item_category: 'Subscription',
        item_category2: undefined,
        item_brand: 'Horiz.io',
        quantity: 1,
      },
    ]
  })
}