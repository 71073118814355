<template>
  <BannerIconDescriptionCta
    type="secondary"
    :title="title"
    :description="description"
    :cta-text="ctaText"
    @handleClick="handleClick"
  />
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: 'https://s.horiz.io/chasseimmo_home',
    },
  },
  data() {
    return {
      title: 'Investissement locatif clé en main',
      description:
        'Un chasseur immobilier déniche pour vous le bien idéal dont la rentabilité a été vérifiée ! Biens vides, loués, meublés ou avec travaux, nous avons la solution pour vous.',
      ctaText: 'Je souhaite acheter un bien locatif',
    }
  },
  methods: {
    handleClick() {
      useTracking().track('Click CTA chasseur immo', {})
      window.open(this.url, '_blank')
    },
  },
}
</script>
