<template>
  <svg width="16" height="18" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        opacity="0.4"
        d="M21.4121 2.19385H4.30761C2.41831 2.19385 0.886719 3.72534 0.886719 5.61474V22.7192C0.886719 24.6085 2.41831 26.1401 4.30761 26.1401H21.4121C23.3013 26.1401 24.8329 24.6085 24.8329 22.7192V5.61474C24.8329 3.72534 23.3013 2.19385 21.4121 2.19385ZM19.0501 11.805L12.2084 18.6468C11.9161 18.9392 11.5319 19.0845 11.1494 19.0845C10.7669 19.0845 10.3827 18.9392 10.0904 18.6468L6.66952 15.2259C6.08652 14.6413 6.08652 13.6926 6.66952 13.108C7.25412 12.5233 8.20288 12.5233 8.78747 13.108L11.1494 15.4715L16.9322 9.68709C17.5168 9.10244 18.4655 9.10244 19.0501 9.68709C19.6331 10.2717 19.6331 11.2205 19.0501 11.805Z"
        fill="#AD5CF6"
      />
      <path
        d="M19.0503 11.805L12.2086 18.6468C11.9162 18.9391 11.532 19.0844 11.1495 19.0844C10.767 19.0844 10.3828 18.9391 10.0906 18.6468L6.66967 15.2259C6.08667 14.6413 6.08667 13.6925 6.66967 13.1079C7.25427 12.5233 8.20303 12.5233 8.78768 13.1079L11.1495 15.4714L16.9323 9.68702C17.5169 9.10237 18.4657 9.10237 19.0503 9.68702C19.6333 10.2716 19.6333 11.2204 19.0503 11.805Z"
        fill="#AD5CF6"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="23.9462" height="27.3671" fill="white" transform="translate(0.886719 0.483398)" />
      </clipPath>
    </defs>
  </svg>
</template>
